import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import { Sections } from "components";

const IndexPage = ({ location, data }) => {
  return (
    <Layout location={location} pageTitle="Technology driven by coffee">
      <Sections />
    </Layout>
  );
};

export default IndexPage;
